import { useState, useContext, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import { UserContext, ErrorMessageContext } from '../contexts';

import useAsync from './useAsync';

export default function useFetch(givenOptions = {}) {
  const [options, setOptions] = useState(givenOptions);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [status, setStatus] = useState(null);

  const { userData } = useContext(UserContext);
  const { showErrorMessage, setShowErrorMessage } = useContext(ErrorMessageContext);
  const errorMessage = { current: '' };

  const [, currentUserSessionRequest] = useAsync();
  const [, fetchRequest] = useAsync();
  const [, parseJsonRequest] = useAsync();

  function buildFetchOptions() {
    if (options.s3Call) return { fetchUrl: options.url, body: null };

    const backendUrl = options.calcEngineCall ? process.env.REACT_APP_CALC_ENGINE_URL : process.env.REACT_APP_BACKEND_URL;

    let fetchUrl = `${backendUrl}${options.url}`;

    if (options.urlIds) {
      options.urlIds.forEach((id, indexId) => {
        const urlId = userData.userAttributes?.[id] || userData.metaData?.[id] || userData.transactionData?.[id] || id;
        fetchUrl += indexId !== 0 ? `&${urlId}` : `${urlId}`;
      });
    }

    const body = options.body && Object.keys(options.body).length ? options.body : null;

    if (body && options.bodyIds) {
      options.bodyIds.forEach((id) => {
        body[id] = userData.userAttributes?.[id] || userData.metaData?.[id] || userData.transactionData?.[id] || id;
      });
    }

    return { fetchUrl, body };
  }

  function makeCall() {
    setCallEnded(false);
    setSuccess(false);
    setError(false);
    setResponse(null);
    errorMessage.current = '';

    setLoading(true);

    const { fetchUrl, body } = buildFetchOptions();

    const authSessionPromise = !options.s3Call ? () => Auth.currentSession() : () => { };

    currentUserSessionRequest({
      promise: authSessionPromise,
      onSuccess: (session) => {
        let fetchCall = () => fetch(fetchUrl);
        if (!options.s3Call) {
          const JWT = session.getIdToken().getJwtToken();
          const fetchOptions = ({
            method: options.method || 'get',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${JWT}`,
            },
            ...body && { body: JSON.stringify(body) },
          });
          fetchCall = () => fetch(fetchUrl, fetchOptions);
        }
        fetchRequest({
          promise: fetchCall,
          onSuccess: (fetchData) => {
            setStatus(fetchData.status);
            if (fetchData.status >= 400) {
              setError(true);
              fetchData.text().then((text) => {
                try {
                  const jsonData = JSON.parse(text);
                  errorMessage.current = jsonData.message || jsonData || text;
                } catch (e) {
                  errorMessage.current = text;
                }
                if (options.onError) options.onError(errorMessage.current);
                else {
                  setShowErrorMessage(
                    !showErrorMessage.includes(errorMessage.current) ? `${showErrorMessage}\n${errorMessage.current}` : showErrorMessage,
                  );
                }
              });
            } else if (fetchData.status === 204) {
              setSuccess(true);
              if (options.onSuccess) options.onSuccess(null, 204);
            } else {
              parseJsonRequest({
                promise: () => fetchData.json(),
                onSuccess: (parsedJsonData) => {
                  setResponse(parsedJsonData);
                  if (!options.multipleCalls || options.multipleCalls === 1) {
                    setSuccess(true);
                    if (options.onSuccess) options.onSuccess(parsedJsonData, fetchData.status);
                  }
                },
              });
            }
          },
          onError: (e) => {
            setError(true);
            errorMessage.current = e.toString();
            if (options.onError) options.onError(e);
            else setShowErrorMessage(!showErrorMessage.includes(e.toString()) ? `${showErrorMessage}\n${e.toString()}` : showErrorMessage);
          },
        });
      },
    });
  }

  useEffect(() => { if (options.url && !loading) makeCall(); }, [options]);

  useEffect(() => { if (error || success) setCallEnded(true); }, [error, success]);

  useEffect(() => {
    if (callEnded) {
      if (options.onFinally) options.onFinally();
      setOptions({});
      setLoading(false);
    }
  }, [callEnded]);

  return [{ response, loading, success, callEnded, status }, setOptions];
}
