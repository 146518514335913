import React from 'react';
import PropTypes from 'prop-types';

import {
  Page,
  View,
  Text,
  Image,
} from '@react-pdf/renderer';

import DraftOverlay from '../components/DraftOverlay';

import redwoods from '../assets/images/redwoods.jpg';
import halfSeparatorLine from '../assets/images/half-line.png';


export default function CompanyDescription({
  reportData,
  valuationDate,
}) {
  return (
    <Page
      size="TABLOID"
      orientation="landscape"
      style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#EAEAEB',
      }}
    >
      <View
        style={{
          width: '50%',
          borderRight: '1px',
          borderColor: '#CCCCCC',
        }}
      >
        <Image
          src={redwoods}
          style={{
            height: '100%',
          }}
        />
      </View>
      <View
        style={{
          width: '50%',
        }}
      >
        <View
          style={{
            position: 'absolute',
            top: '0px',
            backgroundColor: '#0C2617',
            height: '48px',
            width: '100%',
          }}
        >
          <Text
            style={{
              fontFamily: 'Roboto',
              fontSize: '16px',
              color: '#EAEAEB',
              position: 'absolute',
              top: '15px',
              left: '48px',
            }}
          >
            Company Description
          </Text>
        </View>
        <View
          style={{
            marginTop: '70px',
            marginLeft: '48px',
            width: '516px',
          }}
        >
          <Text
            style={{
              fontFamily: 'ManropeMed',
              color: '#49454F',
              fontSize: '16px',
              letterSpacing: '0.1px',
              marginBottom: '16px',
            }}
          >
            {reportData.companyInfo.companyName}
          </Text>
          <Text
            style={{
              fontFamily: 'Roboto',
              color: '#49454F',
              fontSize: '14px',
              lineHeight: '0.57mm',
              letterSpacing: '0.25px',
            }}
          >
            {reportData.companyInfo.companyDescription || ''}
          </Text>
        </View>
      </View>
      <View
        style={{
          position: 'absolute',
          bottom: '0px',
          height: '36px',
          width: '100%',
          paddingLeft: '32px',
          paddingRight: '32px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '10px',
            color: '#79747E',
            marginLeft: 'auto',
            marginRight: '14px',
          }}
        >
          {reportData.isVenBioUser ? 'venBio' : 'Redwood Valuation'}
        </Text>
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '12px',
            color: '#79747E',
          }}
        >
          3
        </Text>
      </View>
      <Image
        src={halfSeparatorLine}
        style={{
          position: 'absolute',
          width: '50%',
          right: '0px',
          bottom: '34px',
        }}
      />
      {reportData.isDraft && <DraftOverlay />}
    </Page>
  );
}


CompanyDescription.propTypes = {
  reportData: PropTypes.object.isRequired,
  valuationDate: PropTypes.string.isRequired,
};
