import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import FlagIcon from '../../assets/icons/FlagIcon';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';
import DraftOverlay from '../../components/DraftOverlay';

export default function Volatility({ reportData, totalPageCount, exhibitNumber }) {
  const tableData = reportData.ValuationDateVolatility;

  const selectedVolatility = `${reportData.OptionPricingAllocation.backsolve.selectedVolatility}%`;

  const selectedVolatilityColumns = Object.keys(tableData.volatilityObject)
    .filter((key) => key.includes('Volatility - Valuation Date'))
    .sort((a, b) => a - b)
    .map((key) => {
      const year = parseFloat(key.match(/\d+(?:\.\d+)?/)[0]);
      const yearLabel = year === 1 ? 'year' : 'years';
      return `${year} ${yearLabel}`;
    });

  const table1Headers = [
    'Outlier',
    'Company name',
    'LTM Total Revenue',
    ...selectedVolatilityColumns,
    'Weighted equity\nvolatility',
  ];

  function returnCellData(dataName, rowIndex, pageIndex) {
    if (dataName === 'Company name') return tableData.volatilityObject.Name[rowIndex + (pageIndex * 37)];
    let valToReturn;
    if (dataName === 'LTM Total Revenue') {
      valToReturn = tableData.volatilityObject['Revenue | LTM'][rowIndex + (pageIndex * 37)];
      return valToReturn ?
        valToReturn.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 1, maximumFractionDigits: 1 }) :
        '-';
    }
    if (dataName.includes('year') || dataName.includes('years')) {
      const yearLabel = dataName.split(' ')[0];
      valToReturn = tableData.volatilityObject[`Volatility - Valuation Date | ${yearLabel}.0 year`][rowIndex + (pageIndex * 37)];
    }
    if (dataName === 'Weighted equity\nvolatility') {
      valToReturn = tableData.volatilityObject['Interpolated Volatility'][rowIndex + (pageIndex * 37)] !== '-' ?
        tableData.volatilityObject['Interpolated Volatility'][rowIndex + (pageIndex * 37)] :
        null;
    }
    return valToReturn || '-';
  }

  function returnSummaryData(dataName, rowIndex) {
    let valToReturn;
    if (dataName === 'LTM Total Revenue') {
      valToReturn = tableData.summaryObject['Revenue | LTM'][rowIndex];
      return valToReturn ?
        valToReturn.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 1, maximumFractionDigits: 1 }) :
        '-';
    }
    if (dataName.includes('year') || dataName.includes('years')) {
      const yearLabel = dataName.split(' ')[0];
      valToReturn = tableData.summaryObject[`Volatility - Valuation Date | ${yearLabel}.0 year`][rowIndex];
    }
    if (dataName === 'Weighted equity\nvolatility') {
      valToReturn = tableData.summaryObject['Interpolated Volatility'][rowIndex] !== '-' ?
        tableData.summaryObject['Interpolated Volatility'][rowIndex] :
        null;
    }
    return valToReturn || '-';
  }

  const numberOfPages = Math.floor((tableData.volatilityObject.Name.length + 10) / 37) || 1;

  const footnote = reportData.footnoteData.allocationMethodologySummary.opmDetails.volatilitySelectionMethodology.ac;

  return (
    <>
      {[...Array(numberOfPages)].map((_, i) => i).map((pageNumber, pageIndex) => (
        <Page
          key={pageNumber}
          size="TABLOID"
          orientation="landscape"
          style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
        >
          <TablePageHeader
            companyName={reportData.companyInfo.companyName}
            pageName={`Valuation date Volatility, Industry   |   Exhibit ${exhibitNumber}`}
          />
          <View
            style={{
              marginTop: '84px',
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              width: '1160px',
              border: '1px',
              borderColor: '#AFB1B3',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '24px',
                backgroundColor: '#0C2617',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#8AB9A4',
                  paddingLeft: '16px',
                  letterSpacing: '0.08px',
                  width: '60px',
                  paddingTop: '6px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                  height: '24px',
                }}
              >
                (USD$)
              </Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '24px',
                  width: '300px',
                  marginRight: '8px',
                  paddingLeft: '6px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    color: '#8AB9A4',
                    letterSpacing: '0.08px',
                    paddingTop: '6px',
                    height: '24px',
                  }}
                >
                  (
                </Text>
                <View
                  style={{
                    height: '16px',
                    width: '12px',
                    paddingTop: '1px',
                  }}
                >
                  <FlagIcon fillColor="#98B8AA" />
                </View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    color: '#8AB9A4',
                    letterSpacing: '0.08px',
                    marginRight: '8px',
                    paddingTop: '6px',
                    height: '24px',
                  }}
                >
                  = Outlier)
                </Text>
              </View>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '10px',
                  color: '#E1E2EC',
                  paddingLeft: '16px',
                  letterSpacing: '0.08px',
                  width: '800px',
                  paddingTop: '6px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                  height: '24px',
                }}
              >
                Daily equity volatility
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '40px',
                backgroundColor: '#307157',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              {table1Headers.map((dataName, index) => (
                <View key={dataName}>
                  {dataName === 'Outlier' ? (
                    <View
                      style={{
                        height: '16px',
                        width: '24px',
                        paddingLeft: '10px',
                        marginTop: 'auto',
                      }}
                    >
                      <FlagIcon fillColor="#98B8AA" />
                    </View>
                  ) : (
                    <Text
                      key={dataName}
                      style={{
                        fontFamily: 'RobotoMed',
                        fontSize: '10px',
                        color: '#E1E2EC',
                        width: index === 0 ? '20px' : index === 1 ? '200px' : '137px',
                        marginTop: 'auto',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        letterSpacing: '0.5px',
                        textAlign: index > 1 && 'right',
                        lineHeight: '0.55mm',
                      }}
                    >
                      {dataName}
                      {index === 2 && (
                        <Text
                          key={dataName}
                          style={{
                            color: '#B2C6BE',
                          }}
                        >
                          {'\n$M'}
                        </Text>
                      )}

                    </Text>
                  )}
                </View>
              ))}
            </View>
            {tableData.volatilityObject.Name.slice(pageIndex * 37, (pageIndex + 1) * 37).map((compName, rowIndex) => (
              <View
                key={compName}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '16px',
                  backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                }}
              >
                {table1Headers.map((dataName, cellIndex) => (
                  <View key={dataName}>
                    {cellIndex === 0 ? (
                      <View
                        style={{
                          height: '16px',
                          width: '24px',
                          paddingLeft: '10px',
                          marginTop: '2px',
                        }}
                      >
                        {tableData.volatilityObject.Outlier[rowIndex + (pageIndex * 37)] === 1 && (
                          <FlagIcon fillColor="#307157" />
                        )}
                      </View>
                    ) : (

                      <Text
                        key={dataName}
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: '10px',
                          color: '#49454F',
                          width: cellIndex === 0 ? '20px' : cellIndex === 1 ? '200px' : '137px',
                          paddingLeft: '16px',
                          paddingRight: '16px',
                          letterSpacing: '0.4px',
                          textAlign: cellIndex > 1 && 'right',
                        }}
                      >
                        {returnCellData(dataName, rowIndex, pageIndex) || '-'}
                      </Text>
                    )}
                  </View>
                ))}
              </View>
            ))}
          </View>
          {(pageIndex === numberOfPages - 1) && (tableData.volatilityObject.Name.slice(pageIndex * 37, (pageIndex + 1) * 37).length <= 24) && (
            <>
              <View
                style={{
                  marginTop: '16px',
                  marginLeft: '32px',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '1160px',
                  border: '1px',
                  borderColor: '#AFB1B3',
                }}
              >
                {['Maximum', '95th percentile', '90th percentile',
                  '75th percentile', 'Median', '25th percentile',
                  '10th percentile', '5th percentile', 'Minimum', 'Average',
                ].map((rowName, rowIndex) => (
                  <View
                    key={rowName}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      height: '16px',
                      backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                    }}
                  >
                    {table1Headers.map((dataName, cellIndex) => (
                      <Text
                        key={dataName}
                        style={{
                          fontFamily: cellIndex === 0 ? 'RobotoMed' : 'Roboto',
                          fontSize: '10px',
                          color: '#49454F',
                          width: cellIndex === 0 ? '200px' : '137px',
                          paddingLeft: '16px',
                          paddingRight: '16px',
                          letterSpacing: '0.4px',
                          textAlign: cellIndex !== 0 && 'right',
                        }}
                      >
                        {cellIndex === 0 ? rowName : returnSummaryData(dataName, rowIndex)}
                      </Text>
                    ))}
                  </View>
                ))}
              </View>
              <View
                style={{
                  width: '1160px',
                  marginTop: '16px',
                  marginLeft: '32px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  height: '24px',
                  backgroundColor: '#9ECEBB',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '14px',
                    color: '#49454F',
                    paddingRight: '16px',
                    letterSpacing: '0.08px',
                  }}
                >
                  Selected equity volatility&nbsp;
                  <Text
                    style={{
                      fontFamily: 'RobotoBold',
                      fontSize: '16px',
                      color: '#000000',
                      letterSpacing: '0.1px',
                    }}
                  >
                    {selectedVolatility}
                  </Text>
                </Text>
              </View>
            </>
          )}
          <BottomPagination
            pageNumber={totalPageCount + pageIndex}
            reportData={reportData}
          />
          {reportData.isDraft && <DraftOverlay />}
        </Page>
      ))}
      {tableData.volatilityObject.Name.slice((numberOfPages - 1) * 37, numberOfPages * 37).length > 24 && (
        <Page
          size="TABLOID"
          orientation="landscape"
          style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
        >
          <TablePageHeader
            companyName={reportData.companyInfo.companyName}
            pageName={`Valuation date Volatility, Industry   |   Exhibit ${exhibitNumber}`}
          />
          <View
            style={{
              marginTop: '84px',
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'column',
              width: '1160px',
              border: '1px',
              borderColor: '#AFB1B3',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '40px',
                backgroundColor: '#307157',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              {table1Headers.map((dataName, index) => (
                <Text
                  key={dataName}
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '10px',
                    color: '#E1E2EC',
                    width: index > 1 ? '200px' : '137px',
                    marginTop: 'auto',
                    paddingLeft: '16px',
                    paddingRight: index > 2 ? '10px' : '6px',
                    letterSpacing: '0.5px',
                    textAlign: index !== 1 && 'right',
                    lineHeight: '0.55mm',
                  }}
                >
                  {index > 1 && dataName}
                  {index === 2 && (
                    <Text
                      key={dataName}
                      style={{
                        color: '#B2C6BE',
                      }}
                    >
                      {'\n$M'}
                    </Text>
                  )}
                </Text>
              ))}
            </View>
            {['Maximum', '95th percentile', '90th percentile',
              '75th percentile', 'Median', '25th percentile',
              '10th percentile', '5th percentile', 'Minimum', 'Average',
            ].map((rowName, rowIndex) => (
              <View
                key={rowName}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: '16px',
                  backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                }}
              >
                {table1Headers.map((dataName, cellIndex) => (
                  <Text
                    key={dataName}
                    style={{
                      fontFamily: cellIndex === 0 ? 'RobotoMed' : 'Roboto',
                      fontSize: '10px',
                      color: '#49454F',
                      width: cellIndex === 0 ? '200px' : '137px',
                      display: cellIndex === 1 ? 'none' : 'block',
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      letterSpacing: '0.4px',
                      textAlign: cellIndex !== 0 && 'right',
                    }}
                  >
                    {cellIndex === 0 ? rowName : cellIndex === 1 ? '' : returnSummaryData(dataName, rowIndex)}
                  </Text>
                ))}
              </View>
            ))}
          </View>
          <View
            style={{
              width: '1160px',
              marginTop: '16px',
              marginLeft: '32px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: '24px',
              backgroundColor: '#9ECEBB',
            }}
          >
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '14px',
                color: '#49454F',
                paddingRight: '16px',
                letterSpacing: '0.08px',
              }}
            >
              Selected equity volatility&nbsp;
              <Text
                style={{
                  fontFamily: 'RobotoBold',
                  fontSize: '16px',
                  color: '#000000',
                  letterSpacing: '0.1px',
                }}
              >
                {selectedVolatility}
              </Text>
            </Text>
          </View>
          {!!footnote && (
            <Text
              style={{
                fontFamily: 'Roboto',
                fontSize: '8px',
                color: '#49454F',
                letterSpacing: '0.4px',
                marginTop: '16px',
                marginLeft: '90px',
                width: '450px',
                lineHeight: '0.55mm',
              }}
            >
              Footnote:&nbsp;
              {footnote}
            </Text>
          )}
          <BottomPagination
            pageNumber={totalPageCount + numberOfPages}
            reportData={reportData}
          />
          {reportData.isDraft && <DraftOverlay />}
        </Page>
      )}
    </>
  );
}

Volatility.propTypes = {
  reportData: PropTypes.object.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  exhibitNumber: PropTypes.number.isRequired,
};
