import { commaEvery3rdChar } from '../../../../utils';

const fiscalYears = [
  { name: 'Last fiscal year -1', abbreviation: 'LFY-1' },
  { name: 'Last fiscal year', abbreviation: 'LFY' },
  { name: 'Last twelve months', abbreviation: 'LTM' },
  { name: 'Current fiscal year', abbreviation: 'CFY' },
  { name: 'Next twelve months', abbreviation: 'NTM' },
  { name: 'Next fiscal year', abbreviation: 'NFY' },
  { name: 'Next fiscal year +1', abbreviation: 'NFY+1' },
  { name: 'Next fiscal year +2', abbreviation: 'NFY+2' },
];

const years = [...Array(40)].map((_, index) => `${new Intl.NumberFormat('en-US', { minimumFractionDigits: 1 }).format(index / 4 + 0.25)} year`);

const price = (val) => val ? commaEvery3rdChar((val * 1000000).toFixed(0)) : '-';

const percentage = (val, isGrossMargin) => val ? `${(val * (!isGrossMargin ? 100 : 1)).toFixed(2)}%` : '-';

const multiple = (val) => val ? `${val.toFixed(2)}x` : '-';

const volatility = (val) => val ? `${(val * 100).toFixed(2)}%` : '-';

const getCompsColumns = (compData, tabSelected, filters) => {
  const filterSelected = () => {
    const industryFilterSelected = (
      filters.showRevenue ||
      filters.showVolatilityTrDate ||
      filters.showVolatilityValDate ||
      filters.showMarketCap
    );
    const compsFilterSelected = (
      filters.showCompanyInfo ||
      filters.showRevenue ||
      filters.showEBITDA ||
      filters.showGrossProfit ||
      filters.showMarketCap ||
      !!filters.growthFilterValues.length ||
      !!filters.multiplesFilterValues.length
    );
    return (tabSelected === 'industry' && industryFilterSelected) || (tabSelected === 'comps' && compsFilterSelected);
  };

  const filterFiscalYear = (fiscalYear) => {
    const fiscalYearFilterValue = `${fiscalYear.name} (${fiscalYear.abbreviation})`;
    return !filters.fiscalPeriodFilterValues.length || filters.fiscalPeriodFilterValues.includes(fiscalYearFilterValue);
  };

  const filterYears = (yearIndex) => {
    const yearFilterValue = `${yearIndex / 4 + 0.25} year${yearIndex / 4 + 0.25 > 1 ? 's' : ''}`;
    return !filters.yearsFilterValues.length || filters.yearsFilterValues.includes(yearFilterValue);
  };

  let compsColumns = [
    // Basic info
    {
      field: 'Identifier',
      headerName: 'Capital IQ',
      headerSubName: 'Identifier',
      columnGroupName: 'Basic information',
      filterCondition: filters.showCompanyInfo || tabSelected === 'industry',
      width: 106,
    },
    {
      field: 'Ticker',
      headerName: 'Ticker',
      headerSubName: '(Market:Ticker)',
      columnGroupName: 'Basic information',
      filterCondition: filters.showCompanyInfo || tabSelected === 'industry',
      width: 136,
    },

    {
      field: 'Industry',
      headerName: 'Industry',
      headerSubName: '(Primary)',
      columnGroupName: 'Basic information',
      filterCondition: filters.showCompanyInfo,
      tab: 'comps',
      width: 144,
    },
    {
      field: 'Last FYE Date',
      headerName: 'Last FYE Date',
      headerSubName: '(mm/dd/yyyy)',
      columnGroupName: 'Basic information',
      filterCondition: filters.showCompanyInfo,
      tab: 'comps',
      width: 130,
    },
    {
      field: 'IPO Date',
      headerName: 'IPO Date',
      headerSubName: '(mm/dd/yyyy)',
      columnGroupName: 'Basic information',
      filterCondition: filters.showCompanyInfo,
      tab: 'comps',
      width: 130,
    },
    {
      field: 'Short Description',
      headerName: 'Description',
      headerSubName: '(Short)',
      columnGroupName: 'Basic information',
      filterCondition: filters.showCompanyInfo,
      tab: 'comps',
      width: 114,
      notSortable: true,
    },
    {
      field: 'Long Description',
      headerName: 'Description',
      headerSubName: '(Long)',
      columnGroupName: 'Basic information',
      filterCondition: filters.showCompanyInfo,
      tab: 'comps',
      width: 114,
      notSortable: true,
    },

    // Volatility
    // Volatility since valuation date
    ...years.filter((_, yearIndex) => filterYears(yearIndex)).map((year) => ({
      field: `Volatility - Valuation Date | ${year}`,
      headerName: `${parseFloat(year.replace(' year', '')).toFixed(2)}`,
      headerSubName: parseFloat(year.replace(' year', '')) <= 1 ? 'yr' : 'yrs',
      columnGroupName: !filters.yearsFilterValues.length || filters.yearsFilterValues.length >= 3 ?
        'Volatility since valuation date' : 'Val. Vol.',
      filterCondition: tabSelected === 'industry' && filters.showVolatilityValDate,
      tab: 'industry',
      width: 110,
      format: volatility,
    })),

    // Volatility since transaction date
    ...years.filter((_, yearIndex) => filterYears(yearIndex)).map((year) => ({
      field: `Volatility - Transaction Date | ${year}`,
      headerName: `${parseFloat(year.replace(' year', '')).toFixed(2)}`,
      headerSubName: parseFloat(year.replace(' year', '')) <= 1 ? 'yr' : 'yrs',
      columnGroupName: !filters.yearsFilterValues.length || filters.yearsFilterValues.length >= 3 ?
        'Volatility since transaction date' : 'Tr. Vol.',
      filterCondition: tabSelected === 'industry' && filters.showVolatilityTrDate,
      tab: 'industry',
      width: 110,
      format: volatility,
    })),

    // Market cap
    {
      field: 'Market Capitalization | Transaction Date',
      headerName: 'Transaction date',
      headerSubName: '($)',
      columnGroupName: 'Market capitalization',
      filterCondition: filters.showMarketCap,
      width: 172,
      format: price,
    },
    {
      field: 'Market Capitalization | Valuation Date',
      headerName: 'Valuation date',
      headerSubName: '($)',
      columnGroupName: 'Market capitalization',
      filterCondition: filters.showMarketCap,
      width: 186,
      format: price,
    },
    {
      field: 'Market Capitalization | Financial Statement Date',
      headerName: 'Financial statement date',
      headerSubName: '($)',
      columnGroupName: 'Market capitalization',
      filterCondition: filters.showMarketCap,
      width: 200,
      format: price,
    },

    // Market growth
    {
      field: 'Market Cap Growth',
      headerName: 'Transaction date',
      headerSubName: 'to valuation date',
      columnGroupName: 'Market growth',
      filterCondition:
        filters.growthFilterValues.includes('Market growth') ||
        filters.showMarketCap,
      width: 160,
      format: percentage,
    },

    // Enterprise Value
    {
      field: 'Enterprise Value | Transaction Date',
      headerName: 'Transaction date',
      headerSubName: '($)',
      columnGroupName: 'Enterprise value',
      filterCondition: filters.showMarketCap,
      width: 172,
      format: price,
    },
    {
      field: 'Enterprise Value | Valuation Date',
      headerName: 'Valuation date',
      headerSubName: '($)',
      columnGroupName: 'Enterprise value',
      filterCondition:
        filters.showMarketCap ||
        filters.multiplesFilterValues.includes('Revenue multiples') ||
        filters.multiplesFilterValues.includes('EBITDA multiples'),
      width: 186,
      format: price,
    },
    {
      field: 'Enterprise Value | Financial Statement Date',
      headerName: 'Financial statement date',
      headerSubName: '($)',
      columnGroupName: 'Enterprise value',
      filterCondition: filters.showMarketCap,
      width: 200,
      format: price,
    },

    // Enterprise Value growth
    {
      field: 'Enterprise Value Growth',
      headerName: 'Transaction date',
      headerSubName: 'to valuation date',
      columnGroupName: 'EV growth',
      filterCondition:
        filters.growthFilterValues.includes('Enterprise growth') ||
        filters.showMarketCap,
      width: 160,
      format: percentage,
    },

    // Revenue
    ...fiscalYears.map((fiscalYear) => filterFiscalYear(fiscalYear) && {
      field: `Revenue | ${fiscalYear.abbreviation}`,
      headerName: fiscalYear.name,
      headerSubName: `${fiscalYear.abbreviation} ($)`,
      columnGroupName: 'Revenue',
      filterCondition: filters.showRevenue,
      width: 166,
      format: price,
    }),

    // EBITDA
    ...fiscalYears.map((fiscalYear) => filterFiscalYear(fiscalYear) && {
      field: `EBITDA | ${fiscalYear.abbreviation}`,
      headerName: fiscalYear.name,
      headerSubName: `${fiscalYear.abbreviation} ($)`,
      columnGroupName: 'EBITDA',
      filterCondition: filters.showEBITDA,
      tab: 'comps',
      width: 160,
      format: price,
    }),

    // Gross Profit
    ...fiscalYears.map((fiscalYear) => filterFiscalYear(fiscalYear) && {
      field: `Gross Profit | ${fiscalYear.abbreviation}`,
      headerName: fiscalYear.name,
      headerSubName: `${fiscalYear.abbreviation} ($)`,
      columnGroupName: 'Gross Profit',
      filterCondition: filters.showGrossProfit,
      tab: 'comps',
      width: 160,
      format: price,
    }),

    // Revenue growth rate
    ...fiscalYears.map((fiscalYear) => filterFiscalYear(fiscalYear) && {
      field: `Revenue Growth | ${fiscalYear.abbreviation}`,
      headerName: fiscalYear.name,
      headerSubName: `${fiscalYear.abbreviation} (%)`,
      columnGroupName: 'Revenue growth',
      filterCondition: filters.growthFilterValues.includes('Revenue growth'),
      tab: 'comps',
      width: 160,
      format: percentage,
    }),

    // EBITDA growth
    ...fiscalYears.map((fiscalYear) => filterFiscalYear(fiscalYear) && {
      field: `EBITDA Growth | ${fiscalYear.abbreviation}`,
      headerName: fiscalYear.name,
      headerSubName: `${fiscalYear.abbreviation} (%)`,
      columnGroupName: 'EBITDA growth',
      filterCondition: filters.growthFilterValues.includes('EBITDA growth'),
      tab: 'comps',
      width: 160,
      format: percentage,
    }),

    // Gross Profit growth
    ...fiscalYears.map((fiscalYear) => filterFiscalYear(fiscalYear) && {
      field: `Gross Profit Growth | ${fiscalYear.abbreviation}`,
      headerName: fiscalYear.name,
      headerSubName: `${fiscalYear.abbreviation} (%)`,
      columnGroupName: !filters.fiscalPeriodFilterValues.length || filters.fiscalPeriodFilterValues.length > 1 ?
        'Gross Profit growth' : 'Gross Profit growth r.',
      filterCondition: filters.growthFilterValues.includes('Gross Profit growth'),
      tab: 'comps',
      width: 160,
      format: percentage,
    }),

    // Revenue multiples
    ...fiscalYears.map((fiscalYear) => filterFiscalYear(fiscalYear) && {
      field: `Revenue Multiple | ${fiscalYear.abbreviation}`,
      headerName: fiscalYear.name,
      headerSubName: `${fiscalYear.abbreviation}`,
      columnGroupName: 'Revenue multiples',
      filterCondition: filters.multiplesFilterValues.includes('Revenue multiples'),
      tab: 'comps',
      width: 160,
      format: multiple,
    }),

    // EBITDA multiples
    ...fiscalYears.map((fiscalYear) => filterFiscalYear(fiscalYear) && {
      field: `EBITDA Multiple | ${fiscalYear.abbreviation}`,
      headerName: fiscalYear.name,
      headerSubName: `${fiscalYear.abbreviation}`,
      columnGroupName: 'EBITDA multiples',
      filterCondition: filters.multiplesFilterValues.includes('EBITDA multiples'),
      tab: 'comps',
      width: 160,
      format: multiple,
    }),

    // EBITDA Margin
    ...fiscalYears.map((fiscalYear) => filterFiscalYear(fiscalYear) && {
      field: `EBITDA Margin | ${fiscalYear.abbreviation}`,
      headerName: fiscalYear.name,
      headerSubName: `${fiscalYear.abbreviation} (%)`,
      columnGroupName: 'EBITDA Margin',
      filterCondition: filters.growthFilterValues.includes('EBITDA Margin'),
      tab: 'comps',
      width: 160,
      format: percentage,
    }),

    // Gross Margin
    ...fiscalYears.map((fiscalYear) => filterFiscalYear(fiscalYear) && {
      field: `Gross Margin | ${fiscalYear.abbreviation}`,
      headerName: fiscalYear.name,
      headerSubName: `${fiscalYear.abbreviation} (%)`,
      columnGroupName: 'Gross Margin',
      filterCondition: filters.growthFilterValues.includes('Gross Margin'),
      tab: 'comps',
      width: 160,
      format: (val) => percentage(val, true),
    }),
  ];

  // Filter Columns
  compsColumns = compsColumns.filter((column) => column.tab ? column.tab === tabSelected : true);
  compsColumns = filterSelected() ? compsColumns.filter((column) => column.filterCondition) : compsColumns;
  compsColumns = compsColumns.filter((column) => compData.some((data) => data[column.field]));

  // Group columns
  const groupColumns = (groupName) => compsColumns.filter((column) => column.columnGroupName === groupName);
  const groupedColumnsWidth = (groupName) => groupColumns(groupName).reduce((a, b) => a + b.width, 0);

  const headerGroups = [...new Set(compsColumns.map((column) => column.columnGroupName))];

  compsColumns = headerGroups.flatMap((headerGroup) => groupColumns(headerGroup).length ? [{
    columnGroupName: headerGroup,
    columnGroupChildren: groupColumns(headerGroup),
    columnGroupWidth: groupedColumnsWidth(headerGroup),
  }] : []);

  return compsColumns;
};

export default getCompsColumns;
