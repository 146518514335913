import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';
import DraftOverlay from '../../components/DraftOverlay';

import { commaEvery3rdChar } from '../../../../../../utils';

export default function GeneralNotes({ reportData, totalPageCount, exhibitNumber }) {
  const tableData = reportData.GeneralAppraisersNotes;

  const tableHeaders = [...new Set([
    ...Object.keys(tableData.Financial.Revenue),
    ...Object.keys(tableData.Financial['Gross profit']),
    ...Object.keys(tableData.Financial.EBITDA),
  ])];

  const rowNames = [];

  tableHeaders.forEach((header) => {
    ['Revenue', 'Gross profit', 'EBITDA'].forEach((kpi) => {
      if (tableData.Financial[kpi][header]) {
        const kpiData = Object.keys(tableData.Financial[kpi][header]);
        if (!rowNames.join('').includes(`${kpiData[0]}${kpiData[1]}`)) {
          rowNames.push(kpiData[0], kpiData[1]);
        }
      }
    });
  });

  function returnCellData(headerName, rowName, dataType) {
    if (['Revenue', 'Gross profit', 'EBITDA'].includes(dataType)) {
      if (tableData.Financial[dataType][headerName]) {
        const val = tableData.Financial[dataType][headerName][rowName];
        return val === 'NaN%' ? '—' : val;
      }
      return '—';
    }
    if (tableData.Financial[rowName][headerName]) {
      const val = tableData.Financial[rowName][headerName][rowName];
      return val === '$NaN' ? '—' : val;
    }
    return '—';
  }

  return (
    <Page
      size="TABLOID"
      orientation="landscape"
      style={{ position: 'relative', backgroundColor: '#EAEAEB' }}
    >
      <TablePageHeader
        companyName={reportData.companyInfo.companyName}
        pageName={`General appraiser's Notes   |   Exhibit ${exhibitNumber}`}
      />
      <View
        style={{
          marginTop: '86px',
          marginLeft: '80px',
          width: '1064px',
          height: '96px',
          backgroundColor: '#FAFAFD',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '8px',
            paddingLeft: '16px',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '142px',
            }}
          >
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '10px',
                color: '#49454F',
                paddingBottom: '8px',
                letterSpacing: '0.5px',
              }}
            >
              General
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '124px',
            }}
          >
            {['Client name', 'Valuation date', 'Company name', 'Headquarters'].map((label) => (
              <Text
                key={label}
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  color: '#49454F',
                  paddingBottom: '16px',
                  paddingRight: '16px',
                  letterSpacing: '0.5px',
                  textAlign: 'right',
                }}
              >
                {label}
              </Text>
            ))}
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '314px',
            }}
          >
            {['investorFirmName', 'valuationDate', 'companyName', 'headquarters'].map((dataName) => (
              <Text
                key={dataName}
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  color: '#49454F',
                  paddingBottom: '16px',
                  letterSpacing: '0.5px',
                }}
              >
                {reportData.companyInfo[dataName] || '—'}
              </Text>
            ))}
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '124px',
            }}
          >
            {['Year founded', 'Primary industry', 'Primary sector', 'Website'].map((label) => (
              <Text
                key={label}
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  color: '#49454F',
                  paddingBottom: '16px',
                  letterSpacing: '0.5px',
                }}
              >
                {label}
              </Text>
            ))}
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '314px',
            }}
          >
            {['yearFounded', 'primaryIndustry', 'primarySector', 'website'].map((dataName) => (
              <Text
                key={dataName}
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '10px',
                  color: '#49454F',
                  paddingBottom: '16px',
                  letterSpacing: '0.5px',
                }}
              >
                {reportData.companyInfo[dataName] || '—'}
              </Text>
            ))}
          </View>
        </View>
      </View>
      {rowNames.length > 0 && (
        <View
          style={{
            marginTop: '24px',
            marginLeft: '80px',
            display: 'flex',
            flexDirection: 'column',
            width: '1064px',
            border: '1px',
            borderColor: '#AFB1B3',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: '24px',
              backgroundColor: '#0C2617',
              borderBottom: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '10px',
                color: '#E1E2EC',
                paddingLeft: '16px',
                paddingRight: '16px',
                letterSpacing: '0.08px',
                width: '180px',
              }}
            >
              Financial
            </Text>
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '10px',
                color: '#8AB9A4',
                paddingLeft: '16px',
                letterSpacing: '0.08px',
                width: '200px',
              }}
            >
              (USD$)
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: '28px',
              justifyContent: 'flex-end',
              backgroundColor: '#307157',
              borderBottom: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            {tableHeaders.map((dataName, index) => (
              <Text
                key={dataName}
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#E1E2EC',
                  width: '204px',
                  marginTop: 'auto',
                  ...index === 0 && { paddingLeft: '16px' },
                  paddingRight: '16px',
                  letterSpacing: '0.5px',
                  textAlign: 'right',
                  lineHeight: '0.55mm',
                }}
              >
                {dataName}
              </Text>
            ))}
          </View>
          {rowNames.map((rowName, rowIndex, rootArr) => (
            <View
              key={rowName}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '18px',
                backgroundColor: `${rowIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
              }}
            >
              <Text
                style={{
                  fontFamily: rowIndex % 2 ? 'Roboto' : 'RobotoMed',
                  fontSize: '10px',
                  color: '#49454F',
                  width: '524px',
                  paddingLeft: rowIndex % 2 ? '32px' : '16px',
                  paddingRight: '16px',
                  letterSpacing: '0.4px',
                }}
              >
                {`${rowNames[rowIndex].charAt(0).toUpperCase()}${rowNames[rowIndex].slice(1)}`}
              </Text>
              {tableHeaders.map((headerName) => (
                <Text
                  key={headerName}
                  style={{
                    fontFamily: rowIndex % 2 ? 'Roboto' : 'RobotoMed',
                    fontSize: '10px',
                    color: '#49454F',
                    width: '204px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    marginLeft: 'auto',
                    letterSpacing: '0.4px',
                    textAlign: 'right',
                  }}
                >
                  {returnCellData(headerName, rowName, rootArr[rowIndex - 1]) || 'N/A'}
                </Text>
              ))}
            </View>
          ))}
        </View>
      )}
      <BottomPagination
        pageNumber={totalPageCount}
        reportData={reportData}
      />
      {reportData.isDraft && <DraftOverlay />}
    </Page>
  );
}

GeneralNotes.propTypes = {
  reportData: PropTypes.object.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  exhibitNumber: PropTypes.number.isRequired,
};
